const Bg = () => {
    return (
      <div className="absolute top-0 left-0 z-[-1] w-full h-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="visual"
          viewBox="0 0 1080 540"
          width="100%"
          height="100%"
          version="1.1"
          preserveAspectRatio="xMidYMid slice"
        >
          <rect x="0" y="0" width="1080" height="540" fill="#424242" />
          <path
            d="M0 302L25.7 304.7C51.3 307.3 102.7 312.7 154.2 321C205.7 329.3 257.3 340.7 308.8 347.8C360.3 355 411.7 358 463 359.2C514.3 360.3 565.7 359.7 617 355.8C668.3 352 719.7 345 771.2 336.5C822.7 328 874.3 318 925.8 307.8C977.3 297.7 1028.7 287.3 1054.3 282.2L1080 277L1080 541L1054.3 541C1028.7 541 977.3 541 925.8 541C874.3 541 822.7 541 771.2 541C719.7 541 668.3 541 617 541C565.7 541 514.3 541 463 541C411.7 541 360.3 541 308.8 541C257.3 541 205.7 541 154.2 541C102.7 541 51.3 541 25.7 541L0 541Z"
            fill="#323232"
          />
          <path
            d="M0 344L25.7 342.7C51.3 341.3 102.7 338.7 154.2 341.8C205.7 345 257.3 354 308.8 352.3C360.3 350.7 411.7 338.3 463 342.2C514.3 346 565.7 366 617 380C668.3 394 719.7 402 771.2 402.2C822.7 402.3 874.3 394.7 925.8 385.3C977.3 376 1028.7 365 1054.3 359.5L1080 354L1080 541L1054.3 541C1028.7 541 977.3 541 925.8 541C874.3 541 822.7 541 771.2 541C719.7 541 668.3 541 617 541C565.7 541 514.3 541 463 541C411.7 541 360.3 541 308.8 541C257.3 541 205.7 541 154.2 541C102.7 541 51.3 541 25.7 541L0 541Z"
            fill="#2e2e2e"
          />
          <path
            d="M0 419L25.7 414.7C51.3 410.3 102.7 401.7 154.2 400.2C205.7 398.7 257.3 404.3 308.8 401.5C360.3 398.7 411.7 387.3 463 380.3C514.3 373.3 565.7 370.7 617 371.8C668.3 373 719.7 378 771.2 386.5C822.7 395 874.3 407 925.8 416.8C977.3 426.7 1028.7 434.3 1054.3 438.2L1080 442L1080 541L1054.3 541C1028.7 541 977.3 541 925.8 541C874.3 541 822.7 541 771.2 541C719.7 541 668.3 541 617 541C565.7 541 514.3 541 463 541C411.7 541 360.3 541 308.8 541C257.3 541 205.7 541 154.2 541C102.7 541 51.3 541 25.7 541L0 541Z"
            fill="#2b2b2b"
          />
        </svg>
      </div>
    );
  };

export default Bg;
